function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import { baseFetch, URL_BASE } from "../base-fetch";
const fetchData = function() {
    var _ref = _async_to_generator(function*(url, body, method = "POST") {
        const response = yield baseFetch(url, {
            method,
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            const errorText = yield response.text();
            throw new Error(`HTTP error ${response.status}: ${errorText}`);
        }
        return yield response.json();
    });
    return function fetchData(url, body) {
        return _ref.apply(this, arguments);
    };
}();
export const isBC = function() {
    var _ref = _async_to_generator(function*(email, timeout) {
        return yield fetchData(`${URL_BASE}/contact-sales/bc-qualify`, {
            email,
            timeout
        });
    });
    return function isBC(email, timeout) {
        return _ref.apply(this, arguments);
    };
}();
export const analyzeContactSalesIntent = function() {
    var _ref = _async_to_generator(function*(prompt) {
        return yield fetchData(`${URL_BASE}/contact-sales/analyze-intent`, {
            prompt
        });
    });
    return function analyzeContactSalesIntent(prompt) {
        return _ref.apply(this, arguments);
    };
}();
export const getCalendarLink = function() {
    var _ref = _async_to_generator(function*(details) {
        return yield fetchData(`${URL_BASE}/contact-sales/calendar-link`, details);
    });
    return function getCalendarLink(details) {
        return _ref.apply(this, arguments);
    };
}();
